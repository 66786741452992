import { useState } from 'react';
import React from 'react';
import './ContactComponent.css';
import MapEmbed from './MapEmbed';

const ContactComponent = () => {
  const [contactReason, setContactReason] = useState('');

  const handleContactReasonChange = (event) => {
    setContactReason(event.target.value);
  };

  return (
    <div className="contact-container">
      <h2>Lakor Sarl</h2>
      <p>Email: info@lakor-electricite.ch</p>
      <p>Téléphone: 022 348 25 75</p>
      <p>Adresse: Rue de Genève 3, 1225 Chêne-Bourg</p>

      <form className="contact-form">
        <label>
          Nom Prénom:
          <input type="text" name="name" />
        </label>

        <label>
          Entreprise:
          <input type="text" name="company" />
        </label>

        <label>
          Motif de contact:
          <textarea
            name="reason"
            value={contactReason}
            onChange={handleContactReasonChange}
            rows={10}
          />
        </label>

        <label>
          Entrez votre e-mail ou numéro de téléphone pour recevoir une réponse :
          <input type="text" name="contactInfo" />
        </label>

        <button type="submit">Envoyer</button>
        <div className="map-container">
          <MapEmbed />
        </div>
      </form>
    </div>
  );
};

export default ContactComponent;
